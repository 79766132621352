import React from 'react';
import { addHours, format } from 'date-fns';
import { Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUserLog } from '../../../types/userInterface';

interface Props {
  item: IUserLog;
  onChange: (item: IUserLog) => void;
}

const BitacoraItemComponent: React.FC<Props> = ({ item, onChange }) => {
  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>{item.logType}</Accordion.Header>
      <Accordion.Body className="pl-0 pr-0">
        <div className="form-content">
          <div className="form-row">
            <label htmlFor="date" className="form-label label--icon">
              Día
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Día</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="date"
              className="form-control form--gray"
              id="date"
              placeholder="dd/mm/aaaa"
              value={format(
                item.createdAt
                  ? new Date(item.createdAt)
                  : addHours(new Date(), 6),
                'yyyy-MM-dd'
              )}
              onChange={(e) => {
                const date = new Date(e.target.value);
                const newItem = { ...item, createdAt: date };
                onChange(newItem);
              }}
            />
          </div>
          <div className="form-row">
            <label htmlFor="hour" className="form-label label--icon">
              Hora
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Hora</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="time"
              className="form-control form--gray"
              id="hour"
              value={
                item.createdAt ? format(new Date(item.createdAt), 'HH:mm') : ''
              } // La hora ahora está en el formato HH:mm
              onChange={(e) => {
                const hours = e.target.value.split(':')[0];
                const minutes = e.target.value.split(':')[1];
                const createdAt = item.createdAt
                  ? new Date(item.createdAt)
                  : new Date();
                createdAt.setHours(parseInt(hours), parseInt(minutes));
                const newItem = { ...item, createdAt };
                onChange(newItem);
              }}
            />
          </div>
          <div className="form-row">
            <label htmlFor="note" className="form-label label--icon">
              Nota
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Nota</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <textarea
              className="form-control form--gray"
              id="note"
              placeholder=""
              rows={3}
              value={item.note}
              onChange={(e) => {
                const newItem = { ...item, note: e.target.value };
                onChange(newItem);
              }}
            ></textarea>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default BitacoraItemComponent;
