import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScollToTop/scrollToTop.component';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rsuite/dist/rsuite.min.css';
import { setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';
import './App.css';
import LoginScreen from './pages/Auth/Login/login.screen';
import ForgotPasswordScreen from './pages/Auth/ForgotPassword/forgotPassword.screen';
import UpdatePasswordScreen from './pages/Auth/UpdatePassword/updatePassword.screen';
import GestoraComunitariaIndexScreen from './pages/Auth/GestoraComunitaria/index.screen';
import GestoraComunitariaDemograficosScreen from './pages/Auth/GestoraComunitaria/demograficos.screen';
import GestoraComunitariaAntecedentesScreen from './pages/Auth/GestoraComunitaria/antecedentes.screen';
import GestoraComunitariaCompromisoScreen from './pages/Auth/GestoraComunitaria/compromiso.screen';
import GestoraComunitariaMasScreen from './pages/Auth/GestoraComunitaria/mas.screen';
import GestoraComunitariaProcessScreen from './pages/Auth/GestoraComunitaria/process.screen';
import GestoraIndexScreen from './pages/Dashboard/TablesIndex/gestoraIndex.screen';
import DetailUserScreen from './pages/Dashboard/User/Detail/detail.screen';
// import AccompanimentScreen from './pages/Dashboard/Accompaniment/accompaniment.screen';
import BitacoraScreen from './pages/Dashboard/User/Bitacora/bitacora.screen';
import CapacitacionScreen from './pages/Dashboard/User/Capacitacion/capacitacion.screen';
import AcompananteIndexScreen from './pages/Dashboard/TablesIndex/acompananteIndex.screen';
import ProfesionalIndexScreen from './pages/Dashboard/TablesIndex/profesionalIndex.screen';
import AdminScreen from './pages/Dashboard/Index/admin.screen';
import AllWomenScreen from './pages/Dashboard/TablesIndex/mujeresIndex.screen';
import ColectivasScreen from './pages/Dashboard/Colectivas/colectivas.screen';
// import ColectivaDetailScreen from './pages/Dashboard/Colectivas/colectivaDetail.screen';
import EquipoScreen from './pages/Dashboard/Equipo/equipo.component';
import EditUserScreen from './pages/Dashboard/User/Edit/edit.screen';
import EditUserSuccessScreen from './pages/Dashboard/Success/successEditUser.screen';
import AcompananteDetailScreen from './pages/Dashboard/Admin/Detail/acompananteDetail.screen';
import ChatAdminScreen from './pages/Dashboard/Chat/chatAdmin.screen';
import EditAdminScreen from './pages/Dashboard/Admin/Edit/adminEdit.screen';
import EditAdminSuccessScreen from './pages/Dashboard/Success/successEditAdmin.screen';
import EvaluationAdminScreen from './pages/Dashboard/Admin/Evaluations/evaluationAdmin.screen';
import DonationsScreen from './pages/Dashboard/Donations/donations.screen';
import SolicitudesScreen from './pages/Dashboard/Solicitudes/solicitudes.screen';
import SolicitudDetailScreen from './pages/Dashboard/Solicitudes/solicitudDetail.screen';
import NotFoundScreen from './pages/NotFound/notFound.screen';
import PrivateRoute from './utils/PrivateRoute';
import NewAmigaScreen from './pages/Auth/NewAmiga/newAmiga.screen';
import NewAmigaInfoScreen from './pages/Auth/NewAmiga/newAmigaInfo.screen';
import NewAmigaColectivaScreen from './pages/Auth/NewAmiga/newAmigaColectiva.screen';
import NewAmigaAuthScreen from './pages/Auth/NewAmiga/newAmigaAuth.screen';
import NewAmigaSuccessScreen from './pages/Dashboard/Success/successAccount.screen';
import { AdminPrincipalRol, AdminSteps, IAdmin } from './types/adminInterface';
import { getSession } from './utils/helpers';
import OwnerDetailScreen from './pages/Dashboard/Admin/Detail/ownerDetail';

setDefaultOptions({ locale: es });

function App() {
  const [session, setSession] = useState<Partial<IAdmin>>({});

  const mainRoute = (admin: Partial<IAdmin>) => {
    if (!admin || !admin.principalRol) {
      return <LoginScreen />;
    }
    if (admin.principalRol === AdminPrincipalRol.ADMIN) {
      return <AdminScreen />;
    }
    if (admin.principalRol === AdminPrincipalRol.ACOMPANANTE) {
      if ((admin.step || 0) <= AdminSteps.MEET_OLA_TEAM) {
        return <GestoraComunitariaProcessScreen />;
      }
      return <AllWomenScreen />;
    }
    if (
      [
        AdminPrincipalRol.ACOMPANANTE,
        AdminPrincipalRol.ASESORA_LEGAL,
        AdminPrincipalRol.PSICOLOGA,
      ].includes(admin.principalRol)
    ) {
      return <AllWomenScreen />;
    }
    if ([AdminPrincipalRol.GESTORA].includes(admin.principalRol)) {
      return <AllWomenScreen />;
    }
    return <NotFoundScreen />;
  };

  useEffect(() => {
    const session = getSession();
    if (session) {
      setSession(session);
    }
  }, []);
  return (
    <main className="app-main">
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={mainRoute(session)} />
            <Route path="/iniciar-sesion" element={<LoginScreen />} />
            <Route
              path="/recuperar-contrasena"
              element={<ForgotPasswordScreen />}
            />
            <Route
              path="/actualizar-contrasena"
              element={<UpdatePasswordScreen />}
            />
            <Route
              path="/gestora-paso-1"
              element={<GestoraComunitariaIndexScreen />}
            />
            <Route
              path="/gestora-paso-2"
              element={<GestoraComunitariaDemograficosScreen />}
            />
            <Route
              path="/gestora-paso-3"
              element={<GestoraComunitariaAntecedentesScreen />}
            />
            <Route
              path="/gestora-paso-4"
              element={<GestoraComunitariaCompromisoScreen />}
            />
            <Route
              path="/gestora-paso-5"
              element={<GestoraComunitariaMasScreen />}
            />
            <Route
              path="/gestora-proceso/:id?"
              element={<GestoraComunitariaProcessScreen />}
            />
            <Route
              path="/inicio-gestora"
              element={
                <PrivateRoute principalRol={[`${AdminPrincipalRol.GESTORA}`]}>
                  <GestoraIndexScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/registrar-una-mujer"
              element={
                <PrivateRoute
                  principalRol={[
                    `${AdminPrincipalRol.ADMIN}`,
                    `${AdminPrincipalRol.GESTORA}`,
                  ]}
                >
                  <NewAmigaScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/registrar-una-mujer-informacion"
              element={
                <PrivateRoute
                  principalRol={[
                    `${AdminPrincipalRol.ADMIN}`,
                    `${AdminPrincipalRol.GESTORA}`,
                  ]}
                >
                  <NewAmigaInfoScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/registrar-una-mujer-colectiva"
              element={
                <PrivateRoute
                  principalRol={[
                    `${AdminPrincipalRol.ADMIN}`,
                    `${AdminPrincipalRol.GESTORA}`,
                  ]}
                >
                  <NewAmigaColectivaScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/registrar-una-mujer-acceso"
              element={
                <PrivateRoute principalRol={[`${AdminPrincipalRol.ADMIN}`]}>
                  <NewAmigaAuthScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/registrar-una-mujer-finalizado"
              element={
                <PrivateRoute principalRol={[`${AdminPrincipalRol.ADMIN}`]}>
                  <NewAmigaSuccessScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/acompanante-inicio"
              element={<AcompananteIndexScreen />}
            />
            <Route
              path="/profesional-inicio"
              element={<ProfesionalIndexScreen />}
            />
            <Route
              path="/administrador-inicio"
              element={
                <PrivateRoute principalRol={[`${AdminPrincipalRol.ADMIN}`]}>
                  <AdminScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/mujeres"
              element={
                <PrivateRoute
                  principalRol={[
                    `${AdminPrincipalRol.ADMIN}`,
                    `${AdminPrincipalRol.GESTORA}`,
                    `${AdminPrincipalRol.ACOMPANANTE}`,
                  ]}
                >
                  <AllWomenScreen />
                </PrivateRoute>
              }
            />
            <Route path="/detalle-usuario/:id" element={<DetailUserScreen />} />
            <Route
              path="/mujeres-acompanando"
              element={
                <PrivateRoute
                  principalRol={[
                    AdminPrincipalRol.ACOMPANANTE,
                    AdminPrincipalRol.ASESORA_LEGAL,
                    AdminPrincipalRol.GESTORA,
                    AdminPrincipalRol.PSICOLOGA,
                  ]}
                >
                  <AcompananteIndexScreen />
                </PrivateRoute>
              }
            />
            <Route path="/bitacora/:id" element={<BitacoraScreen />} />
            <Route path="/capacitacion" element={<CapacitacionScreen />} />
            <Route
              path="/colectivas"
              element={
                <PrivateRoute principalRol={[`${AdminPrincipalRol.ADMIN}`]}>
                  <ColectivasScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/colectiva/:id"
              element={
                <PrivateRoute
                  principalRol={[
                    `${AdminPrincipalRol.ADMIN}`,
                    `${AdminPrincipalRol.GESTORA}`,
                    `${AdminPrincipalRol.ACOMPANANTE}`,
                    `${AdminPrincipalRol.ASESORA_LEGAL}`,
                    `${AdminPrincipalRol.PSICOLOGA}`,
                  ]}
                >
                  <ProfesionalIndexScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/equipo-ola"
              element={
                <PrivateRoute
                  principalRol={[
                    `${AdminPrincipalRol.ADMIN}`,
                    `${AdminPrincipalRol.GESTORA}`,
                  ]}
                >
                  <EquipoScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/editar-usuario/:id"
              element={
                <PrivateRoute
                  principalRol={[
                    AdminPrincipalRol.ADMIN,
                    AdminPrincipalRol.GESTORA,
                    AdminPrincipalRol.ACOMPANANTE,
                  ]}
                >
                  <EditUserScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/editar-usuario-finalizado/:id"
              element={
                <PrivateRoute
                  principalRol={[
                    AdminPrincipalRol.ADMIN,
                    AdminPrincipalRol.GESTORA,
                    AdminPrincipalRol.ACOMPANANTE,
                  ]}
                >
                  <EditUserSuccessScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/detalle-acompanante/:id"
              element={
                <PrivateRoute
                  principalRol={[
                    AdminPrincipalRol.ADMIN,
                    AdminPrincipalRol.GESTORA,
                  ]}
                >
                  <AcompananteDetailScreen />
                </PrivateRoute>
              }
            />
            <Route path="/chat-acompanante" element={<ChatAdminScreen />} />
            <Route path="/editar-administrador" element={<EditAdminScreen />} />
            <Route
              path="/editar-administrador-finalizado"
              element={<EditAdminSuccessScreen />}
            />
            <Route path="/evaluaciones" element={<EvaluationAdminScreen />} />
            <Route path="/documentos" element={<DonationsScreen />} />
            <Route
              path="/solicitudes"
              element={
                <PrivateRoute principalRol={[`${AdminPrincipalRol.ADMIN}`]}>
                  <SolicitudesScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/detalle-solicitud/:id"
              element={
                <PrivateRoute principalRol={[`${AdminPrincipalRol.ADMIN}`]}>
                  <SolicitudDetailScreen />
                </PrivateRoute>
              }
            />
            <Route path="/mi-perfil" element={<OwnerDetailScreen />} />
            <Route path="*" element={<NotFoundScreen />} /> {/* Ruta 404 */}
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </main>
  );
}

export default App;
