import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

interface ModalDerivarProps {
  show: boolean;
  onHide: () => void;
  onEnd: () => void;
}

const ModalDerivarOirsComponents: React.FC<ModalDerivarProps> = ({
  show,
  onHide,
  onEnd,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isAdmin] = useState('admin');

  const checkFormValidity = () => {
    const requiredFields = document.querySelectorAll('.requiredField');
    const allFieldsFilled = Array.from(requiredFields).every((field) => {
      const inputField = field as HTMLInputElement; // Aserción de tipo a HTMLInputElement

      if (inputField.type === 'checkbox' || inputField.type === 'radio') {
        return Array.from(requiredFields).some((f) => {
          const input = f as HTMLInputElement;
          return input.name === inputField.name && input.checked;
        });
      }
      return inputField.value.trim() !== '';
    });
    setIsFormValid(allFieldsFilled);
  };

  // Maneja el cambio del checkbox
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    // Llama a checkFormValidity cuando el modal se muestra
    if (show) {
      checkFormValidity();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter">
          {isAdmin == 'admin' ? (
            <>
              <h2 className="text-40 text-center text-500 pb-2">
                Derivación a especialistas y/o OIRS
              </h2>
              <p className="text-24 text-center">
                Esta mujer será derivada a una especialista
              </p>
            </>
          ) : (
            <>
              <h2 className="text-40 text-center text-500 pb-2">
                Derivación a OIRS
              </h2>
              <p className="text-24 text-center">
                Esta mujer se derivará a OIRS
              </p>
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          {isAdmin == 'admin' ? (
            <>
              <div className="form-row">
                <label className="form-label label--icon">
                  ¿Qué especialista deseas que tome este perfil?
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        ¿Qué especialista deseas que tome este perfil?
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <p className="text-gray600">
                  Puedes seleccionar más de una opción si es el caso
                </p>
                <div className="row-check-2 row">
                  <div className="row-check-2__col col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input requiredField"
                        type="checkbox"
                        name="perfil"
                        id="abogada"
                        value="abogada"
                        onChange={checkFormValidity}
                      />
                      <label className="form-check-label" htmlFor="abogada">
                        Abogada
                      </label>
                    </div>
                  </div>
                  <div className="row-check-2__col col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input requiredField"
                        type="checkbox"
                        name="perfil"
                        id="psicologa"
                        value="psicologa"
                        onChange={checkFormValidity}
                      />
                      <label className="form-check-label" htmlFor="psicologa">
                        Psicóloga
                      </label>
                    </div>
                  </div>
                  <div className="row-check-2__col col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input requiredField"
                        type="checkbox"
                        name="perfil"
                        id="gestora"
                        value="gestora"
                        onChange={checkFormValidity}
                      />
                      <label className="form-check-label" htmlFor="gestora">
                        Gestora comunitaria
                      </label>
                    </div>
                  </div>
                  <div className="row-check-2__col col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input requiredField"
                        type="checkbox"
                        name="perfil"
                        id="psiquiatra"
                        value="psiquiatra"
                        onChange={checkFormValidity}
                      />
                      <label className="form-check-label" htmlFor="psiquiatra">
                        Psiquiatra
                      </label>
                    </div>
                  </div>
                  <div className="row-check-2__col col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input requiredField"
                        type="checkbox"
                        name="perfil"
                        id="otro"
                        value="otro"
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="otro">
                        Otro
                      </label>
                    </div>
                  </div>
                  {isChecked && (
                    <div className="row-check-2__col col-4">
                      <label className="form-check-label" htmlFor="otro">
                        ¿Cuál?
                      </label>
                      <input
                        className="form-control requiredField"
                        type="text"
                        name="perfil"
                        id="other"
                        placeholder="Ingresa el perfil a canalizar"
                        onChange={checkFormValidity}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <label className="form-label label--icon">
                  ¿Se ha iniciado ya un reclamo OIRS para esta mujer?
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        ¿Se ha iniciado ya un reclamo OIRS para esta mujer?
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <div className="form-check-multi">
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="radio"
                      name="motivo"
                      id="si"
                      value="si"
                      defaultChecked
                      onChange={checkFormValidity}
                    />
                    <label className="form-check-label" htmlFor="si">
                      Sí
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="radio"
                      name="motivo"
                      id="no"
                      value="no"
                      defaultChecked
                      onChange={checkFormValidity}
                    />
                    <label className="form-check-label" htmlFor="no">
                      No
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="radio"
                      name="motivo"
                      id="noSeguro"
                      value="noSeguro"
                      defaultChecked
                      onChange={checkFormValidity}
                    />
                    <label className="form-check-label" htmlFor="noSeguro">
                      No estoy segura
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <label className="form-label label--icon">
                  ¿Cuáles son los motivos de la derivación?
                </label>
                <textarea
                  className="form-control"
                  placeholder="Ingresa motivos adicionales o situaciones que puedan ayudar a Las Amigas a entender el por qué de esta decisión."
                  id="motivosAdicionales"
                ></textarea>
              </div>
            </>
          ) : (
            <>
              <div className="form-row">
                <label className="form-label label--icon">
                  ¿Se ha iniciado ya un reclamo OIRS para esta mujer?
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        ¿Se ha iniciado ya un reclamo OIRS para esta mujer?
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <div className="form-check-multi">
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="radio"
                      name="motivo"
                      id="si"
                      value="si"
                      defaultChecked
                      onChange={checkFormValidity}
                    />
                    <label className="form-check-label" htmlFor="si">
                      Sí
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="radio"
                      name="motivo"
                      id="no"
                      value="no"
                      defaultChecked
                      onChange={checkFormValidity}
                    />
                    <label className="form-check-label" htmlFor="no">
                      No
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="radio"
                      name="motivo"
                      id="noSeguro"
                      value="noSeguro"
                      defaultChecked
                      onChange={checkFormValidity}
                    />
                    <label className="form-check-label" htmlFor="noSeguro">
                      No estoy segura
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <label className="form-label label--icon">
                  Fecha del reclamo
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  placeholder="Selecciona la fecha del reclamo aquí"
                />
              </div>
              <div className="form-row">
                <label className="form-label label--icon">
                  Motivo del reclamo
                </label>
                <textarea
                  className="form-control"
                  placeholder="Ingresa el motivo por el cuál se abrió un reclamo OIRS."
                  id="motivos"
                ></textarea>
              </div>
              <div className="form-row">
                <label className="form-label label--icon">
                  Nombre del Hospital
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="hospital"
                  placeholder="Ingresa el nombre aquí"
                />
              </div>
              <div className="form-row">
                <label className="form-label label--icon">
                  Número / Folio de reclamo
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="folio"
                  placeholder="Ingresa el folio aquí"
                />
              </div>
              <div className="form-row">
                <label className="form-label label--icon">
                  Notas de seguimiento
                </label>
                <textarea
                  className="form-control"
                  placeholder="Ingresa motivos adicionales o situaciones que puedan ayudar a entender el registro del reclamo OIRS."
                  id="motivosAdicionales"
                ></textarea>
              </div>
            </>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button
            type="submit"
            className="btn btn--type1 btn--370"
            onClick={() => {
              onHide();
              onEnd();
            }}
            disabled={!isFormValid}
          >
            {isAdmin == 'admin'
              ? 'Derivar perfil a especialista'
              : 'Derivar mujer a OIRS'}
          </button>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn--type2 btn--370"
            onClick={onHide}
          >
            Cancelar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDerivarOirsComponents;
