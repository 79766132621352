import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import tiempoEmbarazo from '../../../utils/semanas-dias.json';
import { IUser } from '../../../types/userInterface';
import { format, subDays } from 'date-fns';
import { getUserFile } from '../../../api/usersApi';
import { calculateWeeksAndDays } from '../../../utils/helpers';

interface HealthDataProps {
  user: Partial<IUser>;
  token: string;
  readonly?: boolean;
  setUser?: React.Dispatch<React.SetStateAction<Partial<IUser>>>;
}

const HealthDataComponent: React.FC<HealthDataProps> = ({
  user,
  token,
  readonly,
  setUser,
}) => {
  const [semanasResult, setSemanasResult] = useState({
    semanas: '',
    dias: '',
  });

  const getUserFiles = (user: Partial<IUser>) => {
    if (!readonly) {
      return [];
    }
    const createdAt = new Date(user.saludGinecologica?.createdAt || '');
    const ultrasoundReportFile =
      user.saludGinecologica?.ultrasoundReportFile || [];
    const additionalFiles = user.saludGinecologica?.additionalFiles || [];
    return [
      ...ultrasoundReportFile.map((f) => ({
        name: f.split('/').pop() || '',
        fullName: f || '',
        date: format(createdAt, 'dd/MM/yyyy'),
        hour: format(createdAt, 'HH:mm'),
      })),
      ...additionalFiles.map((f) => ({
        name: f.split('/').pop() || '',
        fullName: f || '',
        date: format(createdAt, 'dd/MM/yyyy'),
        hour: format(createdAt, 'HH:mm'),
      })),
    ];
  };

  const openFile = async (file: string) => {
    try {
      const url = await getUserFile(`public/${file}`, token);
      window.open(url.fileUrl, '_blank');
    } catch (error) {
      console.error('Error al abrir el archivo:', error);
    }
  };

  const getAllQuestions = () => {
    const sexualidadYEmociones = user.sexualidadYEmociones?.questions || [];
    const saludYEmociones = user.saludYEmociones?.questions || [];

    return [
      ...sexualidadYEmociones.map((s) => ({
        ...s,

        source: 'sexualidadYEmociones' as const,
      })),
      ...saludYEmociones.map((s) => ({
        ...s,
        source: 'saludYEmociones' as const,
      })),
    ];
  };

  useEffect(() => {
    const { saludGinecologica } = user;
    if (saludGinecologica) {
      const { pregnancyCalculator } = saludGinecologica;
      if (pregnancyCalculator) {
        const { dateResult } = pregnancyCalculator;
        if (dateResult) {
          const { semanas, dias } = calculateWeeksAndDays(new Date(dateResult));
          setSemanasResult({ semanas: `${semanas}`, dias: `${dias}` });
        }
      }
    }
  }, [user]);

  return (
    <div className="form-block">
      <form>
        <div className="form-row">
          <div className="row">
            <div className="col-6">
              <label className="form-label label--icon">Semanas</label>
              <select
                className="form-select "
                aria-label="Semanas de embarazo"
                defaultValue={'default'}
                name="semanasEmbarazo"
                value={semanasResult.semanas || 'default'}
                disabled={readonly}
                onChange={(e) => {
                  e.preventDefault();
                  setSemanasResult({
                    ...semanasResult,
                    semanas: e.target.value,
                  });
                  if (semanasResult.dias !== '') {
                    const currentDateWithoutTime = new Date(
                      new Date().setHours(0, 0, 0, 0)
                    );
                    const dateResult = subDays(
                      currentDateWithoutTime,
                      Number(semanasResult.dias) + Number(e.target.value) * 7
                    );
                    setUser &&
                      setUser({
                        ...user,
                        saludGinecologica: {
                          ...user.saludGinecologica,
                          pregnancyCalculator: {
                            ...user.saludGinecologica?.pregnancyCalculator,
                            result: `${e.target.value} semanas y ${semanasResult.dias} días`,
                            dateResult,
                          },
                        },
                      });
                  }
                }}
              >
                <option value="default" disabled>
                  Elige una de las opciones
                </option>
                {tiempoEmbarazo.semanasEmbarazo.map((semanas) => (
                  <option key={semanas.id} value={semanas.id}>
                    {semanas.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <label className="form-label label--icon">Días</label>
              <select
                className="form-select "
                aria-label="Días de embarazo"
                defaultValue={'default'}
                name="diasEmbarazo"
                value={semanasResult.dias || 'default'}
                disabled={readonly}
                onChange={(e) => {
                  e.preventDefault();
                  setSemanasResult({
                    ...semanasResult,
                    dias: e.target.value,
                  });
                  if (semanasResult.semanas !== '') {
                    const currentDateWithoutTime = new Date(
                      new Date().setHours(0, 0, 0, 0)
                    );
                    const dateResult = subDays(
                      currentDateWithoutTime,
                      Number(e.target.value) + Number(semanasResult.semanas) * 7
                    );
                    setUser &&
                      setUser({
                        ...user,
                        saludGinecologica: {
                          ...user.saludGinecologica,
                          pregnancyCalculator: {
                            ...user.saludGinecologica?.pregnancyCalculator,
                            result: `${semanasResult.semanas} semanas y ${e.target.value} días`,
                            dateResult,
                          },
                        },
                      });
                  }
                }}
              >
                <option value="default" disabled>
                  Elige una de las opciones
                </option>
                {tiempoEmbarazo.diasPostEmbarazo.map((dias) => (
                  <option key={dias.id} value={dias.id}>
                    {dias.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="row">
            <div className="col-12">
              <label htmlFor="metodo" className="form-label label--icon">
                Método para comprobar
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      Método para comprobar de embarazo del usuario
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <input
                type="text"
                className="form-control"
                id="metodo"
                placeholder="Método para comprobar embarazo"
                value={(user.saludGinecologica?.whatMethodUse || []).join(', ')}
                readOnly={readonly}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.target;
                  if (setUser) {
                    setUser((prevUser) => ({
                      ...prevUser,
                      saludGinecologica: {
                        ...prevUser.saludGinecologica,
                        whatMethodUse: value.split(', '),
                      },
                    }));
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          {readonly && (
            <label htmlFor="metodo" className="form-label text-20">
              Ecografía
            </label>
          )}
          {getUserFiles(user).map((file, index) => (
            <div className="image-user" key={index}>
              <div className="pb-3 pt-3">
                <button
                  className="btn btn--type1"
                  onClick={(e) => {
                    e.preventDefault();
                    openFile(file.fullName);
                  }}
                >
                  Ver archivo
                </button>
              </div>
              <div className="image-user__data">
                <i className="icon icon--image"></i>
                <article>
                  <p className="text-18 pb-2">
                    <strong>{file.name}</strong>
                  </p>
                  <p className="text-18">Subida correctamente</p>
                  <p className="text-18">{file.date}</p>
                  <p className="text-18">{file.hour}</p>
                </article>
              </div>
            </div>
          ))}
        </div>
        <div className="form-row">
          <label htmlFor="gestaciones" className="form-label label--icon">
            Gestaciones anteriores
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Gestaciones anteriores del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="gestaciones"
            placeholder="Gestaciones anteriores"
            value={user.saludGinecologica?.pregnancies || ''}
            readOnly={readonly}
            onChange={(e) => {
              e.preventDefault();
              const { value } = e.target;
              if (setUser) {
                setUser((prevUser) => ({
                  ...prevUser,
                  saludGinecologica: {
                    ...prevUser.saludGinecologica,
                    pregnancies: value,
                  },
                }));
              }
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="intento-aborto" className="form-label label--icon">
            Intentó abortar en este embarazo
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>Intentó abortar en este embarazo del usuario</Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="intento-aborto"
            placeholder="Intentó abortar en este embarazo"
            value={user.saludGinecologica?.tryToAbortEarlier || ''}
            readOnly={readonly}
            onChange={(e) => {
              e.preventDefault();
              const { value } = e.target;
              if (setUser) {
                setUser((prevUser) => ({
                  ...prevUser,
                  saludGinecologica: {
                    ...prevUser.saludGinecologica,
                    tryToAbortEarlier: value,
                  },
                }));
              }
            }}
          />
        </div>
        {getAllQuestions().map((q) => {
          const { id, question, answer, detail, confianzaList, source } = q;
          return (
            <div className="form-row" key={id}>
              <div className="row">
                <div className="col-6">
                  <label
                    htmlFor={`pregunta-${id}`}
                    className="form-label label--icon"
                  >
                    {question}
                  </label>
                  {answer && (
                    <input
                      type="text"
                      className="form-control"
                      id={`pregunta-${id}`}
                      placeholder="Pregunta"
                      value={answer}
                      readOnly={readonly}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        const questions = user[source]?.questions || [];
                        const question = questions.find((q) => q.id === id);
                        if (question) {
                          question.answer = value;
                        }
                        if (setUser) {
                          setUser((prevUser) => ({
                            ...prevUser,
                            [source]: {
                              ...prevUser[source],
                              questions: questions,
                            },
                          }));
                        }
                      }}
                    />
                  )}
                </div>
                <div className="col-6">
                  {detail && (
                    <div className="form-row">
                      <label htmlFor={`detalle-${id}`} className="form-label">
                        Detalle
                      </label>
                      <textarea
                        className="form-control"
                        id={`detalle-${id}`}
                        placeholder="Detalle"
                        value={detail}
                        readOnly={readonly}
                        onChange={(e) => {
                          e.preventDefault();
                          const { value } = e.target;
                          const questions = user[source]?.questions || [];
                          const question = questions.find((q) => q.id === id);
                          if (question) {
                            question.detail = value;
                          }
                          if (setUser) {
                            setUser((prevUser) => ({
                              ...prevUser,
                              [source]: {
                                ...prevUser[source],
                                questions: questions,
                              },
                            }));
                          }
                        }}
                      />
                    </div>
                  )}
                  {(confianzaList || []).length > 0 && (
                    <div className="form-row">
                      <textarea
                        className="form-control"
                        id={`confianza-${id}`}
                        placeholder="Confianza"
                        value={(confianzaList || []).join(', ')}
                        readOnly={readonly}
                        onChange={(e) => {
                          e.preventDefault();
                          const { value } = e.target;
                          const questions = user[source]?.questions || [];
                          const question = questions.find((q) => q.id === id);
                          if (question) {
                            question.confianzaList = value
                              .split(',')
                              .map((c) => c.trim());
                          }
                          if (setUser) {
                            setUser((prevUser) => ({
                              ...prevUser,
                              [source]: {
                                ...prevUser[source],
                                questions: questions,
                              },
                            }));
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </form>
    </div>
  );
};

export default HealthDataComponent;
