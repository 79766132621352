import React, { useState, useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import CustomPagination from '../Paginator/paginator.component';
import { Accordion } from 'react-bootstrap';
import { IAdmin } from '../../../types/adminInterface';
import { Link } from 'react-router-dom';

interface Props {
  admins: IAdmin[];
  pagination: {
    totalDocs: number;
    page: number;
    limit: number;
    totalPages: number;
  };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      totalDocs: number;
      page: number;
      limit: number;
      totalPages: number;
    }>
  >;
}

const UserTableComponent: React.FC<Props> = ({
  admins,
  pagination,
  setPagination,
}) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);

  const columns: TableColumn<IAdmin>[] = [
    {
      name: 'Nombre',
      selector: (row) => row.username,
      format: (row) => {
        return (
          <Link
            to={`/detalle-acompanante/${row._id}`}
            className="text-purple500 text-uppercase user-table-detail-image"
          >
            <figure className="image-39">
              <img src="/assets/images/icons/user-pic-2.svg" alt="User image" />
            </figure>
            <strong>{row.username}</strong>
          </Link>
        );
      },
    },
    {
      name: 'Rol',
      selector: (row) => row.principalRol,
    },
    {
      name: 'Colectiva',
      selector: (row) => row.colectiva?.name || 'N/A',
    },
  ];

  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="data-table table--result">
      {!isMobileView ? (
        <DataTable
          columns={columns}
          data={admins}
          pagination
          paginationComponent={() => {
            return (
              <div className="data-paginator">
                <CustomPagination
                  rowCount={pagination.totalDocs}
                  rowsPerPage={pagination.limit}
                  currentPage={pagination.page}
                  pagesToShow={pagination.totalPages}
                  onChangePage={(page: number) => {
                    setPagination({
                      ...pagination,
                      page,
                    });
                  }}
                />
              </div>
            );
          }}
        />
      ) : (
        <section className="data-table-mobile">
          <div className="data-table-mobile__header">
            <p>
              <strong>Nombre</strong>
            </p>
          </div>
          <Accordion className="data-table-mobile__accordion">
            {admins.map((row, index) =>
              (() => {
                return (
                  <Accordion.Item eventKey={String(index)} key={row._id}>
                    <Accordion.Header>{row.username}</Accordion.Header>
                    <Accordion.Body>
                      <table>
                        <tr>
                          <td className="text-center" colSpan={2}>
                            <figure className="image-60 mh-auto">
                              <img
                                src="/assets/images/icons/user-pic-2.svg"
                                alt="User image"
                              />
                            </figure>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Nombre:</strong>
                          </td>
                          <td className="text-center">{row.username}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Rol:</strong>
                          </td>
                          <td className="text-center">{row.principalRol}</td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Colectiva:</strong>
                          </td>
                          <td className="text-center">
                            {row.colectiva?.name || 'N/A'}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={2} className="text-center">
                            <a
                              href="/detalle-usuario"
                              className="btn btn--type2"
                            >
                              <strong>Ver ficha</strong>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })()
            )}
          </Accordion>
        </section>
      )}
    </div>
  );
};

export default UserTableComponent;
