import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

interface ModalDerivarProps {
  show: boolean;
  onHide: () => void;
  onEnd: () => void;
}

const ModalDerivarOrgComponents: React.FC<ModalDerivarProps> = ({
  show,
  onHide,
  onEnd,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isAdmin] = useState('');

  const checkFormValidity = () => {
    const requiredFields = document.querySelectorAll('.requiredField');
    const allFieldsFilled = Array.from(requiredFields).every((field) => {
      const inputField = field as HTMLInputElement; // Aserción de tipo a HTMLInputElement

      if (inputField.type === 'checkbox' || inputField.type === 'radio') {
        return Array.from(requiredFields).some((f) => {
          const input = f as HTMLInputElement;
          return input.name === inputField.name && input.checked;
        });
      }
      return inputField.value.trim() !== '';
    });
    setIsFormValid(allFieldsFilled);
  };

  useEffect(() => {
    // Llama a checkFormValidity cuando el modal se muestra
    if (show) {
      checkFormValidity();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="text-40 text-center text-500 pb-2">
            Este perfil se derivará a otra colectiva
          </h2>
          <p className="text-24 text-center">
            Recuerda registrar los detalles por los que la mujer decide cambiar
            o abandonar el procedimiento
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-row">
            <label className="form-label label--icon">
              ¿Qué motivos son los mencionados por la mujer para abandonar el
              procedimiento? <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    ¿Qué motivos son los mencionados por la mujer para abandonar
                    el procedimiento?
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <p className="text-gray800">
              Puedes seleccionar más de una opción si es el caso
            </p>
            <div className="row-check-2 row">
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="ubicacion"
                    value="ubicacion"
                    onChange={checkFormValidity}
                  />
                  <label className="form-check-label" htmlFor="ubicacion">
                    Considera complicada la ubicación
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="migrante"
                    value="migrante"
                    onChange={checkFormValidity}
                  />
                  <label className="form-check-label" htmlFor="migrante">
                    Es migrante y no está segura
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="horario"
                    value="horario"
                    onChange={checkFormValidity}
                  />
                  <label className="form-check-label" htmlFor="horario">
                    Considera complicado por su horario personal
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="miedoLaboral"
                    value="miedoLaboral"
                    onChange={checkFormValidity}
                  />
                  <label className="form-check-label" htmlFor="miedoLaboral">
                    Por miedo a perder el trabajo
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="dejarHijos"
                    value="dejarHijos"
                    onChange={checkFormValidity}
                  />
                  <label className="form-check-label" htmlFor="dejarHijos">
                    No tiene con quién dejar a sus hijos para enfrentar una
                    hospitalización
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="miedoConyuge"
                    value="miedoConyuge"
                    onChange={checkFormValidity}
                  />
                  <label className="form-check-label" htmlFor="miedoConyuge">
                    Miedo al cónyuge y agresor
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="recursosEconomicos"
                    value="recursosEconomicos"
                    onChange={checkFormValidity}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="recursosEconomicos"
                  >
                    No tiene recursos económicos para un traslado
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="miedoJuzgar"
                    value="miedoJuzgar"
                    onChange={checkFormValidity}
                  />
                  <label className="form-check-label" htmlFor="miedoJuzgar">
                    Miedo a ser juzgada y/o castigada por la familia
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="noContesta"
                    value="noContesta"
                    onChange={checkFormValidity}
                  />
                  <label className="form-check-label" htmlFor="noContesta">
                    Dejó de contestar los mensajes
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="abortoEspontaneo"
                    value="abortoEspontaneo"
                    onChange={checkFormValidity}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="abortoEspontaneo"
                  >
                    Tuvo un aborto espontáneo
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="problemas"
                    value="problemas"
                    onChange={checkFormValidity}
                  />
                  <label className="form-check-label" htmlFor="problemas">
                    Tuve problemas con ella
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="checkbox"
                    name="motivosDerivar"
                    id="otro"
                    value="otro"
                    onChange={checkFormValidity}
                  />
                  <label className="form-check-label" htmlFor="otro">
                    Otras razones
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
              ¿Cuáles son los motivos de la derivación?
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>¿Cuáles son los motivos de la derivación?</Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <textarea
              className="form-control"
              placeholder="Ingresa motivos adicionales o situaciones que puedan ayudar a la otra colectiva a entender el por qué de esta decisión."
              id="motivos"
            ></textarea>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button
            type="submit"
            className="btn btn--type1 btn--370"
            onClick={() => {
              onHide();
              onEnd();
            }}
            disabled={!isFormValid}
          >
            {isAdmin == 'admin'
              ? 'Derivar perfil a otra colectiva'
              : 'Solicitar derivación a otra colectiva'}
          </button>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn--type2 btn--370"
            onClick={onHide}
          >
            Cancelar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDerivarOrgComponents;
