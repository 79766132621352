import { AdminPrincipalRol, IAdmin, IAdminTag } from './adminInterface';

export interface StrapiDoc {
  documentId?: string;
  value?: string;
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  BLOCKED = 'blocked',
}

export enum UserSource {
  ADMIN = 'admin',
  OLA = 'ola',
}

export enum UserSteps {
  PROCEDIMIENTO_COMPLETO = 0,
  INFORMACION_DE_CONTACTO = 1,
  CODIGO_DE_ACTIVACION = 2,
  DATOS_SOCIODEMOGRÁFICOS = 3,
  CAUSALES = 4,
  CONTEXTO_SOCIOCULTURAL = 5,
  SALUD_GINECOLOGICA = 6,
  SALUD_EMOCIONES = 7,
  SEXUALIDAD_EMOCIONES = 8,
  VIOLENTOMETRO = 9,
  INCIDENCIAS = 10,
  MI_PROCESO = 11,
  ACOMPANAMIENTO = 12,
  LLAMADA = 13,
  LLAMADA_SUCCESS = 14,
  GESTION = 15,
  BITACORA = 16,
  BITACORA_SUCCESS = 17,
  EVALUACION_NO_HOSPITAL = 18,
  EVALUACION_HOSPITAL = 19,
  EVALUACION_NO_LEY = 20,
  POST_DELIVERY_STATUS = 21,
  PROCESS_COMPLETE = 22,
}

export interface IUserNote {
  _id?: string;
  note: string;
  adminId: IAdmin | string;
  createdAt: string;
}

export interface IUserLog {
  _id?: string;
  logId: number;
  logType: string;
  note: string;
  status: string;
  adminId: IAdmin | string;
  createdAt?: Date;
}

export interface IUserAssignedTo {
  adminId: IAdmin | string;
  principalRol?: AdminPrincipalRol;
  username?: string;
  greeting?: string;
  chatRoomId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IUserHospitalSelected {
  hospitalId: number;
  isSelected?: boolean;
}

export interface IUserBitacora {
  id: number;
  question: StrapiDoc;
  answer: string;
}

export interface IPostDeliveryStatus {
  question: StrapiDoc;
  answer: string;
}

export interface IUser {
  _id: string;
  source: UserSource;
  userId: number;
  assignedTo?: IUserAssignedTo[];
  username: string;
  email: string;
  phone: string;
  emailVerified: boolean;
  phoneVerified: boolean;
  step: number;
  status: UserStatus;
  colectiva: StrapiDoc & {
    createdAt?: string;
    updatedAt?: string;
  };
  datosSociodemograficos: {
    currentAge?: number;
    country?: { id: number; name: string };
    state?: { id: number; name: string };
    city?: { id: number; name: string };
    createdAt?: string;
    updatedAt?: string;
  };
  datosCausales: {
    causales?: {
      documentId: number;
      number: number;
      description: string;
    }[];
    signUpReason?: string;
    createdAt?: string;
    updatedAt?: string;
  };
  contextoSociocultural: {
    principalJob?: string;
    secondaryJob?: string;
    additionalJob?: string;
    educationLevel?: string;
    isOriginTown?: boolean;
    originTown?: string;
    afroDescendant?: string;
    liveWith?: string;
    createdAt?: string;
    updatedAt?: string;
  };
  saludGinecologica: {
    pregnancies?: string;
    cesareanBirth?: string;
    vaginalBirth?: string;
    abortions?: string;
    tryToAbortEarlier?: string;
    healthForecast?: string;
    healthForecastOther?: string;
    healthServiceShouldGo?: string;
    healthServiceShouldGoOther?: string;
    howManyWeeks?: string;
    pregnancyCalculator?: {
      year?: number;
      month?: number;
      day?: number;
      result?: string;
      weeks?: number;
      days?: number;
      dateResult?: string | Date;
    };
    whatMethodUse?: string[];
    ultrasoundReportFile?: string[];
    additionalFiles?: string[];
    createdAt?: string;
    updatedAt?: string;
  };
  saludYEmociones: {
    questions?: {
      id: number;
      question: string;
      answer: string;
      detail?: string;
      confianzaList?: string[];
    }[];
    source?: 'saludYEmociones' | 'sexualidadYEmociones';
    createdAt?: string;
    updatedAt?: string;
  };
  sexualidadYEmociones: {
    questions?: {
      id: number;
      question: string;
      answer: string;
      detail?: string;
      confianzaList?: string[];
    }[];
    source?: 'sexualidadYEmociones' | 'saludYEmociones';
    createdAt?: string;
    updatedAt?: string;
  };
  violentometro: {
    niveles?: {
      id: number;
      value: string;
    }[];
    createdAt?: string;
    updatedAt?: string;
  };
  incidenciaComunicaciones: {
    questions?: {
      id: number;
      question: string;
      answer: string;
      enterasteList?: string[];
    }[];
    createdAt?: string;
    updatedAt?: string;
  };
  tags: IAdminTag[];
  notes: IUserNote[];
  logs: IUserLog[];
  cancelReason?: {
    reasons?: string[];
    observations?: string;
  };
  derivada: {
    derivadaA?: string;
    motivo?: string[];
    detalle?: string;
    createdAt?: string;
  };
  possibleWorkshops: string[];
  workshopSelected: {
    workshopId: string;
    createdAt?: string;
  };
  apuntes?: string;
  workshopStatus?: string;
  hospitalsSelected: IUserHospitalSelected[];
  bitacora: IUserBitacora[];
  postDeliveryStatus?: IPostDeliveryStatus[];
  createdAt: string;
  updatedAt: string;
}

export interface IUserPaginate {
  docs: IUser[];
  totalDocs?: number;
  offset: number;
  limit: number;
  totalPages?: number;
  page?: number;
  pagingCounter?: number;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  prevPage?: number;
  nextPage?: number;
}
