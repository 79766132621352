import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import HeaderShared from '../../../../shared/header.shared';
import FooterShared from '../../../../shared/footer.shared';
import BitacorasComponent from '../../../../components/Forms/bitacoras/bitacoras.component';
import HeaderAdminShared from '../../../../shared/headerAdmin.shared';
import { IUser, UserStatus } from '../../../../types/userInterface';
import { AdminPrincipalRol, IAdmin } from '../../../../types/adminInterface';
import { getSession } from '../../../../utils/helpers';
import { getUser, updateUser } from '../../../../api/usersApi';
import LoaderComponent from '../../../../components/Loader/loader.component';
import { fetchStrapiSingle } from '../../../../api/strapi';
import { strapiDoc } from '../../../../types/strapiDocs';

const BitacoraScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [sectionContent, setSectionContent] = useState<strapiDoc>();
  const [user, setUser] = useState<Partial<IUser>>({});
  const [session, setSession] = useState<Partial<IAdmin>>({});

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const setError = (message: string) => {
    console.error(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchSection = async () => {
    try {
      const response = await fetchStrapiSingle('/bitacora-de-gestora', {
        populate: '*',
      });
      if (response.data) {
        setSectionContent(response.data);
      }
    } catch (error) {
      console.error('Error al obtener la sección:', error);
      setError('Error al obtener la sección');
    }
  };

  const fetchUser = async () => {
    try {
      setLoading(true);
      if (!id) {
        throw new Error('No se pudo obtener el id del usuario');
      }
      const session = getSession();
      const response = await getUser(id, session?.token || '');

      if (session) {
        setSession(session);
      }
      if (response.status === UserStatus.BLOCKED) {
        navigate('/mi-colectiva');
      }
      setUser(response);
    } catch (error) {
      setError('Error al obtener la información del usuario');
    } finally {
      setLoading(false);
    }
  };

  const updateAmiga = async (updatedUser: Partial<IUser>) => {
    try {
      setLoading(true);
      const session = getSession();
      const response = await updateUser(
        user._id || '',
        updatedUser,
        session?.token || ''
      );
      setUser(response);
    } catch (error) {
      setError('Error al actualizar el usuario');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSection();
    fetchUser();
  }, []);

  return (
    <>
      {[AdminPrincipalRol.ADMIN, AdminPrincipalRol.GESTORA].includes(
        session.principalRol as AdminPrincipalRol
      ) ? (
        <HeaderAdminShared />
      ) : (
        <HeaderShared />
      )}
      <section className="module40">
        {loading && <LoaderComponent />}
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="mb-4 col-12">
                <a href="#" className="btn--back" onClick={() => navigate(-1)}>
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-lg-5 col-12">
                <h1 className="mb-3">Bitácora de gestora</h1>
                <article className="col-12 col-reset">
                  <p className="mb-0 text-32 text-500">{user.username || ''}</p>
                  <p className="text-24 text-gray700 text-500">
                    No. {user.userId || ''}
                  </p>
                  <p>
                    Fecha de ingreso:{' '}
                    {user.createdAt
                      ? format(new Date(user.createdAt), 'dd/MMMM/yy')
                      : ''}{' '}
                  </p>
                </article>
                <h2 className="text-32">Actividad</h2>
                {sectionContent && (
                  <BitacorasComponent
                    user={user}
                    updateAmiga={updateAmiga}
                    actividades={sectionContent.attributes.actividades || []}
                    session={session}
                  />
                )}
              </div>
              <aside className="col-lg-7 col-12 d-flex justify-content-end hide-mobile">
                <figure className="image-270">
                  <img
                    src="/assets/images/illustration/book.png"
                    alt="Bitácora"
                  />
                </figure>
              </aside>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default BitacoraScreen;
