import React, { useEffect, useState } from 'react';
import { deleteSession, getSession } from '../utils/helpers';
import { AdminPrincipalRol, AdminSteps, IAdmin } from '../types/adminInterface';

type Props = {
  // Define your component props here
};

const HeaderShared: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [isNotificationOn, setNotificationOn] = useState(true);
  const [session, setSession] = useState<Partial<IAdmin>>({});

  const isActive = (path: string): string => {
    return location.pathname === path ? 'active' : '';
  };

  const canCreateAdmin = () => {
    const { principalRol, step } = session;
    if (principalRol) {
      if (principalRol === AdminPrincipalRol.ADMIN) {
        return true;
      }
      if (
        principalRol === AdminPrincipalRol.GESTORA &&
        step &&
        step >= AdminSteps.GET_OLA_ACCOUNT
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const session = getSession();
    if (session && session.token) {
      setIsLogged(true);
      setSession(session);
    } else {
      setIsLogged(false);
    }
    setNotificationOn(false);
  }, []);

  return (
    <header>
      <div className="container">
        <div className="header__col col-12">
          <a href="/" className="header__logo">
            <figure>
              <img
                src={`${
                  isOpen
                    ? '/assets/images/logo/ola-white.png'
                    : '/assets/images/logo/ola.png'
                }`}
                alt="OLA Chile"
              />
            </figure>
          </a>
          <nav className={`main-nav ${isOpen ? 'active' : ''}`}>
            <ul>
              {isLogged ? (
                <>
                  {session.principalRol === AdminPrincipalRol.ACOMPANANTE &&
                    (session.step || 0) >= AdminSteps.GET_OLA_ACCOUNT && (
                      <>
                        <li>
                          <a href="/mujeres" className={isActive('/mujeres')}>
                            Mujeres
                          </a>
                        </li>
                        <li>
                          <a
                            href="/mujeres-acompanando"
                            className={`
                            ${isActive('/mujeres-acompanando')}
                            ${isActive('/acompanante-inicio')}
                          `}
                          >
                            Mujeres acompañando
                          </a>
                        </li>
                      </>
                    )}
                  {canCreateAdmin() && (
                    <li>
                      <a
                        href="/registrar-una-mujer"
                        className={`btn btn--type1 ${isActive(
                          '/registrar-una-mujer'
                        )}`}
                      >
                        Registrar una mujer
                      </a>
                    </li>
                  )}
                </>
              ) : (
                <>
                  <li>
                    <a
                      href="/gestora-paso-1"
                      className={isActive('/gestora-paso-1')}
                    >
                      Quiero ser Acompañante
                    </a>
                  </li>
                  <li>
                    <a
                      href="/iniciar-sesion"
                      className={isActive('/iniciar-sesion')}
                    >
                      Iniciar sesión
                    </a>
                  </li>
                </>
              )}
              {isLogged ? (
                <>
                  <li className="main-nav__mobile">
                    <a
                      href="#"
                      className={isActive('/dona')}
                      onClick={(e) => {
                        e.preventDefault();
                        deleteSession();
                        setIsLogged(true);
                        setTimeout(() => {
                          window.location.href = '/';
                        }, 1000);
                      }}
                    >
                      Cerrar sesión
                    </a>
                  </li>
                </>
              ) : (
                ''
              )}
            </ul>
          </nav>
          {isLogged ? (
            <div className="header__options">
              {isNotificationOn ? (
                <>
                  <a href="/" className="icon-header ">
                    <i className="icon icon--notification-on"></i>
                  </a>
                </>
              ) : (
                ''
              )}
              <div className="user-control">
                <button type="button" className="user-control-button">
                  <i className="icon icon--admin-small"></i>
                </button>
                <div className="user-options">
                  <div className="user-options__top">
                    <i className="icon icon--user-big"></i>
                    <article>
                      <h3 className="text-regular text-20">
                        {session.username || ''}
                      </h3>
                      <a href="/mi-perfil">Ver perfil</a>
                    </article>
                  </div>
                  <div className="user-options__bottom">
                    <ul>
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            deleteSession();
                            setIsLogged(true);
                            setTimeout(() => {
                              window.location.href = '/';
                            }, 1000);
                          }}
                        >
                          <i className="icon icon--logout"></i>
                          <span>Cerrar sesión</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <button
            type="button"
            className={`open-nav ${isOpen ? 'active' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(!isOpen);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderShared;
