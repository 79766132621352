import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import HeaderShared from '../../../../shared/header.shared';
import HeaderAdminShared from '../../../../shared/headerAdmin.shared';
import FooterShared from '../../../../shared/footer.shared';
import TagsComponent from '../../../../components/Forms/tags/tags.component';
import { TAGS_DATA } from '../../../../utils/tags';
import TagsManagerComponent from '../../../../components/Forms/tagsManager/tagsManager.component';
import ActionsAcompananteButtonComponent from '../../../../components/Elements/Buttons/actionsAcompanante.component';
import { AdminPrincipalRol, IAdmin } from '../../../../types/adminInterface';
import LoaderComponent from '../../../../components/Loader/loader.component';
import { getSession } from '../../../../utils/helpers';
import { getAdmin, updateAdmin } from '../../../../api/adminApi';
import { format } from 'date-fns';
import { IUser } from '../../../../types/userInterface';
import { searchUsers } from '../../../../api/usersApi';
import GestoraTableComponent from '../../../../components/Elements/DataTable/gestoraTable.component';

const AcompananteDetailScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<Partial<IAdmin>>({});
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [users, setUsers] = useState<IUser[]>([]);
  const [pagination, setPagination] = useState({
    totalDocs: 0,
    page: 1,
    limit: 20,
    totalPages: 0,
  });

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const setError = (message: string) => {
    console.error(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchAdmin = async () => {
    try {
      setLoading(true);
      const session = getSession();
      if (!session || !session.token) {
        navigate('/login');
        return;
      }
      if (!id) {
        navigate(-1);
        return;
      }
      const response = await getAdmin(id, session.token);
      if (response) {
        setAdmin(response);
        if (
          ![AdminPrincipalRol.ADMIN, AdminPrincipalRol.GESTORA].includes(
            response.principalRol
          )
        ) {
          const responseUsers = await searchUsers(
            session.token,
            { assignedTo: [{ adminId: response._id }] },
            { page: 1, limit: 100 }
          );
          if (responseUsers) {
            setUsers(responseUsers.docs);
            setPagination({
              totalDocs: responseUsers.totalDocs || 0,
              page: responseUsers.page || 1,
              limit: responseUsers.limit,
              totalPages: responseUsers.totalPages || 1,
            });
          }
        }
      }
      setSession(session);
    } catch (error) {
      console.error('Error al obtener la sesión:', error);
      setError('Error al obtener la sesión');
    } finally {
      setLoading(false);
    }
  };

  const saveAdmin = async (admin: Partial<IAdmin>, adminId: string) => {
    try {
      setLoading(true);
      const response = await updateAdmin(adminId, admin, session.token || '');
      setAdmin(response);
    } catch (error) {
      setError('Ocurrió un error al actualizar los datos');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAdmin();
  }, []);

  const tagsShow = ['tag-10']; // Definir los id de las tags que se quieren mostrar

  const tagsFiltered = TAGS_DATA.filter((tag) => tagsShow.includes(tag.id));
  return (
    <>
      {[AdminPrincipalRol.ADMIN, AdminPrincipalRol.GESTORA].includes(
        session.principalRol as AdminPrincipalRol
      ) ? (
        <HeaderAdminShared />
      ) : (
        <HeaderShared />
      )}
      <section className="module40">
        {loading && <LoaderComponent />}
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="mb-3 col-12">
                <a href="#" className="btn--back" onClick={() => navigate(-1)}>
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="view-head__data col-12">
                <div className="row">
                  <div className="view-detail col-md-8 col-12">
                    <h1 className="text-40">{admin.username || ''}</h1>
                    <p className="text-20">{admin.principalRol || ''}</p>
                    <div className="view-detail__tags">
                      <TagsComponent tags={tagsFiltered} />
                    </div>
                    <div className="mb-4 col-md-8 col-12 col-reset">
                      <table className="table-user-data">
                        <tbody>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--message-clip"></i>{' '}
                                Registrada:
                              </p>
                            </td>
                            <td>
                              {admin.createdAt
                                ? format(
                                    new Date(admin.createdAt),
                                    'dd/MMMM/yy'
                                  )
                                : ''}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--user-clip"></i>{' '}
                                Acompañamientos:
                              </p>
                            </td>
                            <td>14 mujeres</td>
                          </tr>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--edit-clip"></i>Rol
                                Principal:
                              </p>
                            </td>
                            <td>{admin.principalRol || 'N/A'}</td>
                          </tr>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--edit-clip"></i> Rol
                                Secundario:
                              </p>
                            </td>
                            <td>
                              {admin.secondaryRol ? (
                                admin.secondaryRol
                              ) : (
                                <span className="text-gray600">
                                  Sin Asignar
                                </span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-12 col-reset">
                      <h4 className="mb-3">Especialista en</h4>
                      <TagsManagerComponent
                        admin={session}
                        user={{
                          tags: admin.specialistIn || [],
                        }}
                        handleAddition={(tags) => {
                          saveAdmin(
                            {
                              specialistIn: tags,
                            },
                            admin._id || ''
                          );
                        }}
                      />
                    </div>
                  </div>
                  <aside className="view-detail__action no-flex col-md-4 col-12">
                    <div className="mb-3 view-detail__action-col col-12 col-reset">
                      <a href="/chat-acompanante" className="btn btn--chat">
                        Ver Chat <i className="icon icon--chat"></i>
                      </a>
                      <ActionsAcompananteButtonComponent />
                    </div>
                  </aside>
                </div>
              </div>
            </div>
            {users.length > 0 && (
              <div className="view-body module-top40">
                <div className="row">
                  <div className="mb-4 col-12">
                    <h4 className="mb-4">Todas las mujeres asignadas</h4>
                    <GestoraTableComponent
                      users={users}
                      pagination={pagination}
                      setPagination={setPagination}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default AcompananteDetailScreen;
