import React from 'react';
import { IUser } from '../../../types/userInterface';

interface InfoDataProps {
  user: Partial<IUser>;
  readonly?: boolean;
  setUser?: React.Dispatch<React.SetStateAction<Partial<IUser>>>;
}

const RetroDataComponent: React.FC<InfoDataProps> = ({ user, readonly }) => {
  return (
    <div className="form-block">
      <form>
        {(user.postDeliveryStatus || []).map((bitacora, index) => (
          <div className="form-row" key={index}>
            <label
              htmlFor={`question-${index}`}
              className="form-label label--icon"
            >
              <strong>{bitacora.question.value}</strong>
            </label>
            <textarea
              className="form-control"
              id={`question-${index}`}
              name={`question-${index}`}
              readOnly={readonly}
              value={bitacora.answer || ''}
            />
          </div>
        ))}
      </form>
    </div>
  );
};

export default RetroDataComponent;
