import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { MessageBoxType } from 'react-chat-elements';
import { io, Socket } from 'socket.io-client';
import HeaderShared from '../../../../shared/header.shared';
import FooterShared from '../../../../shared/footer.shared';
import Accordion from 'react-bootstrap/Accordion';
import TagsManagerComponent from '../../../../components/Forms/tagsManager/tagsManager.component';
import NotesComponent from '../../../../components/Elements/Notes/notes.component';
import TagsComponent from '../../../../components/Forms/tags/tags.component';
import { TAGS_DATA } from '../../../../utils/tags';
import PersonalDataComponent from '../../../../components/Forms/userDetail/personalData.component';
import DemographicDataComponent from '../../../../components/Forms/userDetail/demographicData.component';
import HealthDataComponent from '../../../../components/Forms/userDetail/healthData.component';
import InfoDataComponent from '../../../../components/Forms/userDetail/infoData.component';
import ModalDerivarComponents from '../../../../components/Elements/Modal/ModalDerivar/modalDerivar.component';
import ModalDerivarOrgComponents from '../../../../components/Elements/Modal/ModalDerivar/modalDerivarOrg.component';
import ModalDerivarOrgEndComponents from '../../../../components/Elements/Modal/ModalDerivar/modalDerivarOrgEnd.component';
import ModalDerivarOirsComponents from '../../../../components/Elements/Modal/ModalDerivar/modalDerivarOirs.component';
import ModalDerivarOirsEndComponents from '../../../../components/Elements/Modal/ModalDerivar/modalDerivarOirsEnd.component';
import ModalProcessComponents from '../../../../components/Elements/Modal/ModalProcess/modalProcess.component';
import ModalDerivarGrupoComponents from '../../../../components/Elements/Modal/ModalDerivarAdmin/modalDerivarGrupo.component';
import ModalDerivarGrupoEndComponents from '../../../../components/Elements/Modal/ModalDerivarAdmin/modalDerivarGrupoEnd.component';
import ModalDerivarProfesionalComponents from '../../../../components/Elements/Modal/ModalDerivarAdmin/modalDerivarProfesional.component';
import ModalDerivarProfesionalEndComponents from '../../../../components/Elements/Modal/ModalDerivarAdmin/modalDerivarProfesionalEnd.component';
import ActionsButtonComponent from '../../../../components/Elements/Buttons/actions.component';
import {
  IUser,
  IUserLog,
  UserSource,
  UserStatus,
  UserSteps,
} from '../../../../types/userInterface';
import { AdminPrincipalRol, IAdmin } from '../../../../types/adminInterface';
import { calculateWeeksAndDays, getSession } from '../../../../utils/helpers';
import { getUser, updateUser } from '../../../../api/usersApi';
import HeaderAdminShared from '../../../../shared/headerAdmin.shared';
import LoaderComponent from '../../../../components/Loader/loader.component';
import { fetchChatMessages } from '../../../../api/chatApi';
import ChatModalComponent from '../../../../components/Chat/chatModal.component';
import { Calendar } from 'rsuite';
import withReactContent from 'sweetalert2-react-content';
import PostGestionDataComponent from '../../../../components/Forms/userDetail/PostGestionDataComponent';
import ViolentometerComponent from '../../../../components/Forms/userDetail/ViolentometerComponent';
import RetroDataComponent from '../../../../components/Forms/userDetail/RetroDataComponent';

const DetailUserScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [userLogs, setUserLogs] = useState<IUserLog[]>([]);
  const [session, setSession] = useState<Partial<IAdmin>>({});
  const [tagsShow, setTagsShow] = useState<string[]>([]);
  const [messages, setMessages] = useState<MessageBoxType[]>([]);
  const [modalShowChat, setModalShowChat] = useState(false);
  const [socket, setSocket] = useState<Socket>();

  const [modalShow, setModalShow] = useState(false);
  const [modalShowOrg, setModalShowOrg] = useState(false);
  const [modalShowOrgEnd, setModalShowOrgEnd] = useState(false);
  const [modalShowOirs, setModalShowOirs] = useState(false);
  const [modalShowOirsEnd, setModalShowOirsEnd] = useState(false);
  const [modalShowProcess, setModalShowProcess] = useState(false);
  const [modalShowOrgStatus, setModalShowOrgStatus] = useState(false);
  const [modalShowOrgStatusEnd, setModalShowOrgStatusEnd] = useState(false);
  const [modalShowProfessionalStatus, setModalShowProfessionalStatus] =
    useState(false);
  const [modalShowProfessionalStatusEnd, setModalShowProfessionalStatusEnd] =
    useState(false);
  const [modalType] = useState('');

  const tagsFiltered = TAGS_DATA.filter((tag) => tagsShow.includes(tag.id));
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const MySwal = withReactContent(Swal);

  const setError = (message: string) => {
    console.error(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchUser = async () => {
    try {
      setLoading(true);
      if (!id) {
        throw new Error('No se pudo obtener el id del usuario');
      }
      const session = getSession();
      const response = await getUser(id, session?.token || '');

      if (session) {
        setSession(session);
      }
      if (response.status === UserStatus.BLOCKED) {
        navigate('/mi-colectiva');
      }
      setUser(response);
    } catch (error) {
      setError('Error al obtener la información del usuario');
    } finally {
      setLoading(false);
    }
  };

  const updateAmiga = async (updatedUser: Partial<IUser>) => {
    try {
      setLoading(true);
      const session = getSession();
      const response = await updateUser(
        user._id || '',
        updatedUser,
        session?.token || ''
      );
      setUser(response);
    } catch (error) {
      setError('Error al actualizar el usuario');
    } finally {
      setLoading(false);
    }
  };

  const determineTagsToShow = (user: Partial<IUser>) => {
    const tags: string[] = [];
    const userSource = user.derivada?.derivadaA || '';
    const violentometro = user.violentometro?.niveles || [];

    if (userSource === UserSource.OLA) {
      tags.push('tag-1'); // DERIVADA DE OLA
    }
    if (userSource === 'oirs') {
      tags.push('tag-3'); // CUENTA ACTUALIZADA
    }
    if ((user?.datosSociodemograficos?.currentAge || 99) <= 18) {
      tags.push('tag-4'); // ES MENOR DE EDAD
    }
    if (violentometro.some((level) => level.id >= 10)) {
      tags.push('tag-5'); // Situación de violencia
    }
    if (user?.status === UserStatus.ACTIVE) {
      tags.push('tag-10'); // Cuenta activa
    }
    if (user?.status === UserStatus.INACTIVE) {
      tags.push('tag-11'); // CUENTA DESACTIVADA TEMPORALMENTE
    }
    setTagsShow(tags);
  };

  const setNewMessage = (msg: MessageBoxType) => {
    setMessages((prevMessages) => [...prevMessages, msg]);
    // scroll to the bottom of the chat element with class .rce-mlist
    setTimeout(() => {
      const chatContainer = document.querySelector('.rce-mlist');
      if (chatContainer) {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight + 200,
          behavior: 'smooth',
        });
      }
    }, 300);
  };

  const getSocket = async () => {
    const socket = io('https://olachat.manifesto.design/');
    socket.on('connect', () => {
      console.log('Connected to server');
    });
    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });
    setSocket(socket);
  };

  const fetchOldMessages = async (
    roomId: string,
    token: string,
    userId: string
  ) => {
    try {
      setLoading(true);
      const response = await fetchChatMessages(roomId, token);

      const messages = response.docs.reverse().map((doc) => {
        const message: MessageBoxType = {
          id: doc._id || '',
          position: doc.sender === userId ? 'right' : 'left',
          type: 'text',
          text: doc.message,
          date: new Date(doc.createdAt || ''),
          avatar:
            doc.sender === userId
              ? '/assets/images/icons/user-small.svg'
              : '/assets/images/icons/user-pic-2.svg',
          title:
            doc.sender === userId ? session.username || 'Tu' : doc.senderName,
          focus: false,
          forwarded: false,
          replyButton: false,
          removeButton: false,
          titleColor: '#000000',
          status: doc.sender === userId ? 'sent' : 'received',
          notch: true,
          retracted: false,
        };
        return message;
      });

      setMessages([...messages]);
    } catch (error) {
      console.error('Error fetching old messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const getChatRoomId = () => {
    const assignedTo = (user.assignedTo || []).find(
      (assigned) => (assigned.adminId as IAdmin)._id === session._id
    );
    return assignedTo?.chatRoomId || '';
  };

  const formatPregnancyCalculator = (row: Partial<IUser>) => {
    const { saludGinecologica } = row;
    if (saludGinecologica) {
      const { pregnancyCalculator } = saludGinecologica;
      if (pregnancyCalculator) {
        const { result, dateResult } = pregnancyCalculator;
        if (dateResult) {
          const { semanas, dias } = calculateWeeksAndDays(new Date(dateResult));
          return `${semanas} semanas ${dias} días`;
        }
        return result || 'N/A';
      }
    }
    return 'N/A';
  };

  const onScheduleCall = async () => {
    const outUserLogs: IUserLog[] = [];
    MySwal.fire({
      title: 'Agendar llamada',
      html: (
        <>
          <Calendar
            style={{ display: 'inline-block' }}
            compact={true}
            onSelect={(date) => {
              const newUserLogs = [
                ...(userLogs || []).filter(
                  (log) => log.logType !== 'Llamada telefónica'
                ),
              ];
              const newLog: IUserLog = {
                logId: 0,
                logType: 'Llamada telefónica',
                note: `Se agenda una llamada telefónica para el día ${format(
                  date,
                  'dd/MMMM/yy'
                )}`,
                adminId: session._id || '',
                status: 'active',
              };
              newUserLogs.push(newLog);
              setUserLogs(newUserLogs);
              outUserLogs.push(...newUserLogs);
            }}
          />
        </>
      ),
      showCancelButton: true,
      confirmButtonText: 'Agendar',
      cancelButtonText: 'Cancelar',
    }).then((resp) => {
      if (resp.isConfirmed) {
        onSwalConfirm(outUserLogs);
      }
    });
  };

  const onSwalConfirm = async (outUserLogs: IUserLog[]) => {
    await updateAmiga({
      _id: user._id,
      logs: outUserLogs || [],
      step: UserSteps.LLAMADA,
    });
    MySwal.fire({
      title: 'Llamada agendada',
      text: 'La llamada ha sido agendada correctamente',
      icon: 'success',
    });
  };

  const onConfirmScheduleCall = async () => {
    MySwal.fire({
      icon: 'question',
      title: 'Confirmar',
      text: '¿Estás seguro de que deseas confirmar la llamada?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (resp) => {
      if (resp.isConfirmed) {
        const copyUserLogs = [...(userLogs || [])];
        copyUserLogs.push({
          logId: 0,
          logType: 'Llamada telefónica',
          note: 'Se confirmó la llamada telefónica',
          adminId: session._id || '',
          status: 'active',
        });
        await updateAmiga({
          _id: user._id,
          logs: copyUserLogs || [],
          step: UserSteps.LLAMADA_SUCCESS,
        });
        MySwal.fire({
          title: 'Llamada agendada',
          text: 'La llamada ha sido confirmada correctamente',
          icon: 'success',
        });
      }
    });
  };

  const onConfirmGestion = async () => {
    MySwal.fire({
      icon: 'question',
      title: 'Confirmar',
      text: '¿Estás seguro de que deseas confirmar la gestión?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (resp) => {
      if (resp.isConfirmed) {
        const copyUserLogs = [...(userLogs || [])];
        copyUserLogs.push({
          logId: 0,
          logType: 'Selección de hospitales',
          note: 'Se confirmó la gestión',
          adminId: session._id || '',
          status: 'active',
        });
        await updateAmiga({
          _id: user._id,
          logs: copyUserLogs || [],
          step: UserSteps.BITACORA,
        });
        MySwal.fire({
          title: 'Gestión confirmada',
          text: 'La gestión ha sido confirmada correctamente',
          icon: 'success',
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUser();
  }, []);

  useEffect(() => {
    console.log('useEffect - userLogs', { userLogs });
  }, [userLogs]);

  useEffect(() => {
    determineTagsToShow(user);
    setUserLogs(user.logs || []);
  }, [user]);

  useEffect(() => {
    getSocket();
  }, []);

  return (
    <>
      {/*** MODALS ***/}
      <>
        <ModalDerivarComponents
          show={modalShow}
          onHide={() => setModalShow(false)}
          onNext={() => {
            setModalShow(false);
            setModalShowOrg(true);
          }}
          onNextTwo={() => {
            setModalShow(false);
            setModalShowOirs(true);
          }}
        />
        <ModalDerivarOrgComponents
          show={modalShowOrg}
          onHide={() => setModalShowOrg(false)}
          onEnd={() => {
            setModalShowOrg(false);
            setModalShowOrgEnd(true);
          }}
        />
        <ModalDerivarOrgEndComponents
          show={modalShowOrgEnd}
          onHide={() => setModalShowOrgEnd(false)}
        />
        <ModalDerivarOirsComponents
          show={modalShowOirs}
          onHide={() => setModalShowOirs(false)}
          onEnd={() => {
            setModalShowOirs(false);
            setModalShowOirsEnd(true);
          }}
        />
        <ModalDerivarOirsEndComponents
          show={modalShowOirsEnd}
          onHide={() => setModalShowOirsEnd(false)}
        />
        <ModalProcessComponents
          user={user}
          show={modalShowProcess}
          onHide={() => setModalShowProcess(false)}
          setModalShowChat={() => {
            const roomId = getChatRoomId();
            const token = session.token || '';
            const userId = session._id || '';
            fetchOldMessages(roomId, token, userId).then(() =>
              setModalShowChat(true)
            );
          }}
          onScheduleCall={onScheduleCall}
          onConfirmScheduleCall={onConfirmScheduleCall}
          onConfirmGestion={onConfirmGestion}
        />
        {user && socket && (
          <ChatModalComponent
            admin={session}
            roomId={getChatRoomId()}
            token={session?.token || ''}
            show={modalShowChat}
            messages={messages}
            onHide={() => setModalShowChat(false)}
            setNewMessage={setNewMessage}
            socket={socket}
          />
        )}
        <ModalDerivarGrupoComponents
          show={modalShowOrgStatus}
          onHide={() => setModalShowOrgStatus(false)}
          onEnd={() => {
            setModalShowOrgStatus(false);
            setModalShowOrgStatusEnd(true);
          }}
        />
        <ModalDerivarGrupoEndComponents
          show={modalShowOrgStatusEnd}
          onHide={() => setModalShowOrgStatusEnd(false)}
        />

        <ModalDerivarProfesionalComponents
          show={modalShowProfessionalStatus}
          onHide={() => setModalShowProfessionalStatus(false)}
          onEnd={() => {
            setModalShowProfessionalStatus(false);
            setModalShowProfessionalStatusEnd(true);
          }}
        />
        <ModalDerivarProfesionalEndComponents
          show={modalShowProfessionalStatusEnd}
          onHide={() => setModalShowProfessionalStatusEnd(false)}
        />
      </>
      {[AdminPrincipalRol.ADMIN, AdminPrincipalRol.GESTORA].includes(
        session.principalRol as AdminPrincipalRol
      ) ? (
        <HeaderAdminShared />
      ) : (
        <HeaderShared />
      )}
      {loading && <LoaderComponent />}
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="view-head">
              <div className="row">
                <div className="mb-3 col-12">
                  <a
                    href="#"
                    className="btn--back"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    <i className="icon icon--back"></i> Regresar
                  </a>
                </div>
                <div className="view-head__data col-12">
                  <div className="row">
                    <div className="view-detail col-md-8 col-12">
                      <h1 className="text-40">{user.username || ''}</h1>
                      <p className="text-20">
                        <strong>No. {user.userId || ''} </strong>
                      </p>
                      <div className="view-detail__tags">
                        <TagsComponent tags={tagsFiltered} />
                      </div>
                    </div>
                    <aside className="view-detail__action col-md-4 col-12">
                      <div className="view-detail__action-col col-12 col-reset">
                        <button
                          type="button"
                          className="btn btn--type2"
                          onClick={() => setModalShowProcess(true)}
                        >
                          Ver proceso
                        </button>
                        <button
                          type="button"
                          className="btn btn--simple-no-underline"
                          onClick={() => setModalShow(true)}
                        >
                          Derivar perfil
                        </button>
                        {modalType === 'grupo' ? (
                          <button
                            type="button"
                            className="btn btn--simple-no-underline"
                            onClick={() => setModalShowOrgStatus(true)}
                          >
                            Revisar solicitud de derivación
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn--simple-no-underline"
                            onClick={() => setModalShowProfessionalStatus(true)}
                          >
                            Revisar solicitud de derivación
                          </button>
                        )}
                        <ActionsButtonComponent
                          user={user}
                          updateAmiga={updateAmiga}
                          setError={setError}
                          setLoading={setLoading}
                        />
                        {user.derivada?.derivadaA === 'Las amigas' && (
                          <p className="btn text-wine400">
                            Este perfil fue transferido a Las Amigas
                          </p>
                        )}
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
            <div className="view-body module-top40">
              <div className="row">
                <div className="view-body__info col-md-9 col-12">
                  <table className="table-user-data">
                    <tbody>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--message-clip"></i>{' '}
                            Registrada:
                          </p>
                        </td>
                        <td>
                          {user.createdAt
                            ? format(new Date(user.createdAt), 'dd/MMMM/yy')
                            : ''}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--user-clip"></i> Edad:
                          </p>
                        </td>
                        <td>
                          {`${user.datosSociodemograficos?.currentAge || ''}`}{' '}
                          años
                        </td>
                      </tr>
                      {(user.assignedTo || []).map((item, index) => {
                        const admin = item.adminId as IAdmin;
                        return (
                          <tr key={index}>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--user-clip"></i>{' '}
                                {admin.principalRol}
                              </p>
                            </td>
                            <td>{admin.username || 'Sin asignar'}</td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--calendar-clip"></i>{' '}
                            Semanas:
                          </p>
                        </td>
                        <td>{formatPregnancyCalculator(user)}</td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--edit-clip"></i> Derivada
                            de:
                          </p>
                        </td>
                        <td>{`${user.source || ''}`}</td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--edit-clip"></i> Causal:
                          </p>
                        </td>
                        <td>
                          {user.datosCausales?.causales?.length
                            ? Array.from(
                                new Set(
                                  user.datosCausales?.causales.map(
                                    (c) => `${c.number}`
                                  )
                                )
                              ).join(', ')
                            : ''}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--edit-clip"></i> Comuna:
                          </p>
                        </td>
                        <td>{`${
                          user.datosSociodemograficos?.city?.name || ''
                        }`}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="mt-3 col-12">
                    <Link
                      to={`/bitacora/${user._id}`}
                      className="btn btn--type1"
                    >
                      Bitácora de la mujer
                    </Link>
                  </div>
                  <div className="mt-3 col-12">
                    <Accordion
                      defaultActiveKey="0"
                      className="accordion-regular"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Etiquetas</Accordion.Header>
                        <Accordion.Body className="pl-0 pr-0">
                          <TagsManagerComponent
                            admin={session}
                            user={user}
                            showLabel={true}
                            handleAddition={(tags) => {
                              updateAmiga({ _id: user._id || '', tags: tags });
                            }}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Notas</Accordion.Header>
                        <Accordion.Body>
                          <NotesComponent
                            admin={session}
                            user={user}
                            handleAddNote={(note) => {
                              updateAmiga({
                                _id: user._id || '',
                                notes: [...(user.notes || []), note],
                              });
                            }}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Información personal
                        </Accordion.Header>
                        <Accordion.Body>
                          <PersonalDataComponent user={user} readonly={true} />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          Datos Sociodemográficos
                        </Accordion.Header>
                        <Accordion.Body>
                          <DemographicDataComponent
                            user={user}
                            readonly={true}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>Salud y Cuidados</Accordion.Header>
                        <Accordion.Body>
                          <HealthDataComponent
                            user={user}
                            token={session.token || ''}
                            readonly={true}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>Contexto</Accordion.Header>
                        <Accordion.Body>
                          <InfoDataComponent user={user} readonly={true} />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>Violentometro</Accordion.Header>
                        <Accordion.Body>
                          <ViolentometerComponent user={user} readonly={true} />
                        </Accordion.Body>
                      </Accordion.Item>
                      {user?.step && user?.step > UserSteps.BITACORA && (
                        <Accordion.Item eventKey="7">
                          <Accordion.Header>
                            Proceso post-gestión
                          </Accordion.Header>
                          <Accordion.Body>
                            <PostGestionDataComponent
                              user={user}
                              readonly={true}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                      {user?.step &&
                        user?.step >= UserSteps.POST_DELIVERY_STATUS && (
                          <Accordion.Item eventKey="8">
                            <Accordion.Header>
                              Retroalimentación
                            </Accordion.Header>
                            <Accordion.Body>
                              <RetroDataComponent user={user} readonly={true} />
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                    </Accordion>
                  </div>
                </div>
                {getChatRoomId() !== '' && (
                  <aside className="view-body__aside col-md-3 col-12">
                    <a
                      href="#"
                      className="btn btn--chat"
                      onClick={(e) => {
                        e.preventDefault();
                        const roomId = getChatRoomId();
                        const token = session.token || '';
                        const userId = session._id || '';
                        fetchOldMessages(roomId, token, userId).then(() =>
                          setModalShowChat(true)
                        );
                      }}
                    >
                      Ver Chat <i className="icon icon--chat"></i>
                    </a>
                  </aside>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default DetailUserScreen;
