import React from 'react';
import { Accordion } from 'react-bootstrap';

interface Props {
  name: string;
  date: string;
  hour: string;
  note?: string;
}

const BitacoraItemSavedComponent: React.FC<Props> = ({
  name,
  date,
  hour,
  note,
}) => {
  // Implement your component logic here

  return (
    <>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{name}</Accordion.Header>
        <Accordion.Body className="pl-0 pr-0">
          <div className="note-row">
            <p>
              {date} - {hour} hrs.
            </p>
            {note && (
              <div className="note-row__body">
                <p>{note}</p>
              </div>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default BitacoraItemSavedComponent;
