import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Accordion, Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import BitacoraItemComponent from '../../Elements/BitacoraItem/bitacoraItem.component';
import BitacoraItemSavedComponent from '../../Elements/BitacoraItem/bitacoraItemSaved.component';
import { IUser, IUserLog } from '../../../types/userInterface';
import { IAdmin } from '../../../types/adminInterface';

interface Activity {
  id: number;
  name: string;
}
interface Props {
  user: Partial<IUser>;
  session: Partial<IAdmin>;
  actividades: Activity[];
  updateAmiga: (updatedUser: Partial<IUser>) => Promise<void>;
}

const BitacorasComponent: React.FC<Props> = ({
  user: { _id: userId, logs },
  session,
  actividades,
  updateAmiga,
}) => {
  const [userLogs, setUserLogs] = useState<IUserLog[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    setUserLogs(logs || []);
  }, [logs]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateAmiga({ _id: userId, logs: userLogs }).then(() => {
      Swal.fire({
        icon: 'success',
        title: '!Listo!',
        text: 'Se ha registrado la actividad',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Finalizar',
        showCancelButton: true,
      }).then((result) => {
        if (!result.isConfirmed) {
          navigate(-1);
        }
      });
    });
  };

  return (
    <>
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label htmlFor="nation" className="form-label label--icon">
              Selecciona una de las opciones a registrar{' '}
              <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>Selecciona una de las opciones a registrar</Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <div className="mb-4 col-12">
              <select
                className="form-select"
                aria-label="Tu nacionalidad"
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value === 0) {
                    return;
                  }
                  const foundedActivity = actividades.find(
                    (activity) => activity.id === value
                  );
                  const logs = [
                    {
                      logId: value,
                      logType: foundedActivity?.name || '',
                      note: '',
                      status: 'active',
                      adminId: session._id || '',
                    },
                    ...userLogs,
                  ];
                  setUserLogs(logs);
                }}
                value={0}
              >
                <option value="0">Elige una de las opciones</option>
                {actividades
                  .filter(
                    (a) =>
                      !userLogs
                        ?.filter(({ _id }) => _id)
                        .map(({ logId }) => logId)
                        .includes(a.id)
                  )
                  .map((actividad) => (
                    <option key={actividad.id} value={actividad.id}>
                      {actividad.name}
                    </option>
                  ))}
              </select>
            </div>
            <Accordion defaultActiveKey="0" className="mb-4 accordion-regular">
              {userLogs.reverse().map((item, index) => (
                <div key={item.logId} className="accordion-wrapp">
                  {item._id ? (
                    <BitacoraItemSavedComponent
                      name={item.logType}
                      date={
                        item.createdAt
                          ? format(new Date(item.createdAt), 'yyyy/MM/dd')
                          : ''
                      }
                      hour={
                        item.createdAt
                          ? format(new Date(item.createdAt), 'HH:mm')
                          : ''
                      }
                      note={item.note}
                    />
                  ) : (
                    <BitacoraItemComponent
                      item={item}
                      onChange={(data) => {
                        const userLogsCopy = [...userLogs];
                        userLogsCopy[index] = data;
                        setUserLogs(userLogsCopy);
                      }}
                    />
                  )}
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn--danger text-12"
                      onClick={(e) => {
                        e.preventDefault();
                        Swal.fire({
                          title: '¡Atención!',
                          text: '¿Estás seguro de eliminar esta actividad?',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Sí, eliminar',
                          cancelButtonText: 'Cancelar',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setUserLogs(userLogs.filter((_, i) => i !== index));
                          }
                        });
                      }}
                    >
                      Eliminar actividad
                    </button>
                  </div>
                </div>
              ))}
            </Accordion>
          </div>
          <div className="form-row">
            <button type="submit" className="btn btn--type3 btn--100">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BitacorasComponent;
